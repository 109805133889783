// go through stream list's dates on the left side, ensuring each date summary is shown
// only once at the beginning

const summaryClass = 'stream-card__date';
const getDate = e => e.querySelector('.o-date:last-child').textContent.toLowerCase(); //Casing changes depending on the classes on the element

module.exports = () => {
	const summaries = Array.from(document.querySelectorAll(`.${summaryClass}`));
	summaries.forEach((e, i) => {
		e.classList[i > 0 && getDate(summaries[i - 1]) === getDate(e) ? 'add' : 'remove'](`${summaryClass}--hidden`);
	});
};
