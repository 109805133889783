const oDate = require('@financial-times/o-date').default;
const { oLazyLoad } = require('../../lib/lazyload');
const dedupeSummaries = require('../../lib/dedupe-summaries');

module.exports = {
	init: () => {
		const streamList = document.querySelector('[data-stream-list]');

		if (streamList) {
			oLazyLoad.observe();
			oDate.init(streamList);
			dedupeSummaries();
		}
	}
};
