/* eslint-disable max-len */

// This client side javascript is based on next-article's code
// https://github.com/Financial-Times/next-article/blob/5d3a2d9a2de12f2e1d0a88c5165d6445cccdde5b/client/components/flourish/replace-graphics.js
// The dotcom-pages' consumer Apps need to have this client side javascript by themselves until dotcom-pages provides them
// This function replaces flourish fallback images provided by dotcom-pages/view/components/flourish-embed to the actual interactive graphics
// -------------------------------------------------------

// TBC: we are not sure yet this tracking is needed for hub pages
// import trackFailure from './track-failure';

/**
 * Replaces flourish chart images with interactive charts
 *
 * @param {{appContext: object}} appContext
 *
 */

//eslint-disable-next-line no-unused-vars
export default function replaceWithFlourish (appContext) {
	if (!window.Flourish) {
		window.Flourish = { disable_autoload: true };
		const flourishScript = document.createElement('script');
		flourishScript.src = 'https://public.flourish.studio/resources/embed.js';
		flourishScript.defer = true;

		// TBC: we are not sure yet this tracking is needed for hub pages
		// flourishScript.addEventListener('error', () => {
		// 	trackFailure(appContext.appContext)
		// })

		flourishScript.addEventListener('load', () => {
			document.body.dispatchEvent(
				new CustomEvent('FLOURISH_LOADED', {
					bubbles: true,
				}),
			);
			replaceAll();
		});
		// attach the script after we've added the event listeners
		document.body.appendChild(flourishScript);
	} else {
		replaceAll();
	}
}

function replaceAll () {
	const graphicsToReplace = document.querySelectorAll('[data-asset-type="flourish"]');
	graphicsToReplace.forEach((element) => {
		const attributes = element.attributes;
		const flourishIdAttrib = attributes.getNamedItem('data-flourish-id');

		if (flourishIdAttrib) {
			const flourishId = flourishIdAttrib.value;
			const replacement = document.createElement('div');
			replacement.className = 'flourish-embed';
			// query string of any value is required to hide the Flourish signature on the embedded graphic
			replacement.dataset.src = `${flourishId}?hideSignature`;
			element.replaceWith(replacement);
			window.Flourish.loadEmbed(replacement);
		}
	});
}
